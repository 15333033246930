@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/NotoSans-Thin.woff2") format("woff2"),
  url("../fonts/NotoSans-Thin.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/NotoSans-Light.woff2") format("woff2"),
  url("../fonts/NotoSans-Light.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/NotoSans-DemiLight.woff2") format("woff2"),
  url("../fonts/NotoSans-DemiLight.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/NotoSans-Regular.woff2") format("woff2"),
  url("../fonts/NotoSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/NotoSans-Medium.woff2") format("woff2"),
  url("../fonts/NotoSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/NotoSans-Black.woff2") format("woff2"),
  url("../fonts/NotoSans-Black.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans Korean";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/NotoSans-Bold.woff2") format("woff2"),
  url("../fonts/NotoSans-Bold.woff") format("woff");
}
