@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Space Grotesk", sans-serif !important;
  color: #191a23;
  background: #fcfcfa !important;
}

a {
  text-decoration: none;
}

input:focus,
textarea:focus {
  outline: none;
}

.title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.title h1 {
  background-color: #b9ff66;
  padding: 0 5px;
  font-size: 40px;
  font-weight: 600;
}

.title p {
  width: 40%;
  font-size: 18px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 4%;
  border-bottom: 2px solid #e5e5e5;
  box-sizing: border-box;
  background: #fcfcfa;
}

.headerNav {
  display: flex;
  align-items: center;
  gap: 1rem;
  box-sizing: border-box;
}

.headerNavInner {
  font-size: 20px;
  color: #191a23;
  font-weight: 400;
  cursor: pointer;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  padding: 5px 15px;
  border-radius: 8px;
}
.headerNavInner:hover {
  border-radius: 8px;
  transition: all 0.3s;
  background: #191a23;
  color: white;
  font-weight: bold;
}

header nav .quote a {
  border: 1px solid #191a23;
  border-radius: 14px;
  display: flex;
  height: 68px;
  width: 231px;
  align-items: center;
  justify-content: center;
}

.sectionBanner {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 5rem 5%;
  gap: 3rem;
}

.sectionBannerLeft {
  width: 35%;
}

.sectionBannerRight {
  width: 60%;
}

.sectionBannerLeftH1 {
  font-size: 60px;
  font-family: Space Grotesk, sans-serif !important ;
  color: #252422;
}
.sectionBannerLeftH1Name {
  font-family: Space Grotesk, sans-serif !important ;
  color: #eb5e28;
  padding: 1rem 0;
  text-decoration: underline;
  font-weight: bold;
  transition: color 0.3s ease;
}
.sectionBannerLeftH1Name:hover {
  animation: rainbow 5s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
@keyframes rainbow {
  0% {
    background-image: linear-gradient(
      90deg,
      violet,
      indigo,
      blue,
      green,
      yellow,
      orange,
      red
    );
    background-size: 200% 100%;
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-image: linear-gradient(
      90deg,
      violet,
      indigo,
      blue,
      green,
      yellow,
      orange,
      red
    );
    background-size: 200% 100%;
    background-position: 0% 50%;
  }
}
.sectionBannerLeftP {
  margin: 2rem 0;
  font-size: 20px;
  line-height: 2.3;
  font-family: Space Grotesk, sans-serif !important;
  color: #252422;
}

a.link {
  background-color: #191a23;
  color: #fff;
  height: 68px;
  width: 264px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  font-size: 20px;
}

.sectionBannerRightImg {
  width: 100%;
  height: 85%;
}

section.partners {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 4%;
  filter: grayscale(100);
}

.services {
  margin: 5rem 4%;
}

.services-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  margin-top: 3rem;
}

.services-items .service {
  background-color: #f3f3f3;
  border-radius: 45px;
  border: 1px solid black;
  box-shadow: 0px 2px 0px 1px rgba(25, 26, 35, 1);
  -webkit-box-shadow: 0px 2px 0px 1px rgba(25, 26, 35, 1);
  -moz-box-shadow: 0px 2px 0px 1px rgba(25, 26, 35, 1);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.services-items .service.green {
  background-color: #b9ff66;
}

.services-items .service.black {
  background-color: #000000;
}

.services-items .service .left {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 25%;
}

.services-items .service .left h2 {
  background-color: #b9ff66;
}

.services-items .service.green .left h2 {
  background-color: #fff;
}

.services-items .service.black .left h2 {
  background-color: #fff;
}

.services-items .service .left .more {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.services-items .service.black .left .more span {
  color: #fff;
}

section.offer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f3f3;
  margin: 0 4%;
  border-radius: 45px;
  padding: 2rem;
}

section.offer .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 40%;
}

section.offer .left p {
  font-size: 18px;
}

section.case {
  margin: 7rem 4%;
}

.studies {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 3rem;
  background-color: #191a23;
  border-radius: 45px;
  padding: 70px 50px;
  gap: 2rem;
}

.studies .study {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 25%;
}

.studies .study p {
  color: #fff;
  font-size: 18px;
  text-align: left;
}

.studies .line {
  height: 186px;
  width: 1px;
  background-color: #fff;
}

.studies .study a {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.studies .study a span {
  color: #b9ff66;
}

section.working {
  margin: 0 4%;
}

.requests {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.request {
  background-color: #fff;
  border-radius: 45px;
  padding: 2rem;
  box-shadow: 0px 2px 0px 1px rgba(25, 26, 35, 1);
  -webkit-box-shadow: 0px 2px 0px 1px rgba(25, 26, 35, 1);
  -moz-box-shadow: 0px 2px 0px 1px rgba(25, 26, 35, 1);
  border: 1px solid #191a23;
}

.request.active {
  background-color: #b9ff66;
}

.request.active .quest {
  border-bottom: 1px solid #000000;
  padding-bottom: 1rem;
}

.request.active .rep {
  display: block;
}

.rep {
  display: none;
}

.request .quest {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.request .quest div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.request .quest div h1 {
  font-size: 60px;
  font-weight: 600;
}

.request .quest div h5 {
  font-size: 30px;
  font-weight: 400;
}

.request .quest .sign {
  width: 58px;
  height: 58px;
  background-color: #f3f3f3;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  line-height: normal;
  border: 1px solid #000000;
}

.request .rep {
  margin-top: 2rem;
  font-size: 18px;
}

section.team {
  margin: 6rem 4%;
}

.testis {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
}

.testis .testi {
  background-color: #fff;
  border-radius: 45px;
  padding: 2rem;
  box-shadow: 0px 2px 0px 1px rgba(25, 26, 35, 1);
  -webkit-box-shadow: 0px 2px 0px 1px rgba(25, 26, 35, 1);
  -moz-box-shadow: 0px 2px 0px 1px rgba(25, 26, 35, 1);
  border: 1px solid #191a23;
}

.testis .testi .head {
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid #000000;
  padding-bottom: 1.5rem;
}

.testis .testi .head div {
  display: flex;
  align-items: end;
  gap: 1rem;
}

.testis .testi .head div .user {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: start;
}

.testis .testi .head div .user h6 {
  font-size: 24px;
  font-weight: 600;
}

.testis .testi .head div .user span {
  font-size: 18px;
  font-weight: 400;
}

.testis .testi p {
  margin-top: 2rem;
}

.team .see-more {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 2rem;
}

.temoignages {
  margin: 0 4%;
}

.temoignages .content {
  background-color: #191a23;
  border-radius: 45px;
  padding: 2rem;
  margin-top: 3rem;
}

.tems {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.tems .tem p {
  color: #fff;
  border: 0.5px solid #b9ff66;
  padding: 2rem;
  border-radius: 45px;
  font-size: 18px;
}

.tems .tem .tem-user {
  margin-top: 1rem;
}

.tems .tem .tem-user h6 {
  color: #b9ff66;
  font-size: 20px;
}

.tems .tem .tem-user span {
  color: #fff;
  font-size: 18px;
}

.dots {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dots .dot {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 100%;
}

.dots .dot.active {
  background-color: #b9ff66;
}

.content .nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin-top: 4rem;
}

.content .nav img:last-child {
  rotate: 180deg;
}

section.contact {
  margin: 6rem 4%;
}

.form-container {
  background-color: #f3f3f3;
  margin: 3rem 20%;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 2rem;
}

.form-container form {
  width: 80%;
}

.form-container form .form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 2rem;
}

.form-container form .form-group input {
  height: 59px;
  border-radius: 14px;
  padding: 1rem;
  border: 1px solid #000000;
}

.form-container form .form-group textarea {
  height: 150px;
  border-radius: 14px;
  padding: 1rem;
  border: 1px solid #000000;
}

.form-container form button {
  background-color: #191a23;
  border-radius: 14px;
  width: 100%;
  height: 59px;
  color: #fff;
  font-size: 20px;
}

footer {
  background-color: #191a23;
  margin: 0 4%;
  border-radius: 45px 45px 0 0;
  padding: 2rem;
}

footer .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

footer .head nav {
  display: flex;
  align-items: center;
  gap: 1rem;
}

footer .head a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
}

footer .head .socials {
  display: flex;
  align-items: center;
  gap: 1rem;
}

footer .body {
  display: flex;
  align-items: stretch;
  margin-top: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

footer .left h2 {
  background-color: #b9ff66;
  color: #191a23;
  width: fit-content;
  font-size: 20px;
  font-weight: 600;
  border-radius: 7px;
  padding: 2px;
}

footer .left p {
  color: #fff;
  margin-top: 1rem;
  font-size: 18px;
}

footer .right {
  background-color: #292a3272;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 14px;
}

footer .right form {
  display: flex;
  align-items: center;
  gap: 1rem;
}

footer .right form input {
  width: 285px;
  border: 1px solid #fff;
  height: 67px;
  background-color: transparent;
  border-radius: 14px;
  padding: 1rem;
  font-size: 20px;
}

footer .right form button {
  background-color: #b9ff66;
  width: 195px;
  height: 67px;
  border-radius: 14px;
  font-size: 20px;
}

footer .bottom {
  text-align: center;
}

footer .bottom p {
  color: #fff;
  font-size: 18px;
}

.btn {
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
}

.btn-primary {
  background-color: #3b82f6;
}

.btn-primary:hover {
  background-color: #2563eb;
}

.btn-primary:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
  opacity: 0.6;
}

.btn-danger {
  background-color: #ef4444;
}

.btn-danger:hover {
  background-color: #dc2626;
}

.btn-danger:disabled {
  background-color: #fca5a5;
  cursor: not-allowed;
  opacity: 0.6;
}
