.carousel-container {
  position: fixed;
  bottom: 10px;
  left: 80px;
}

.carousel {
  display: flex;
  align-items: center;
  position: relative;
  transition: opacity 0.5s ease-in-out;
}

.carousel-visible {
  opacity: 1;
}

.carousel-hidden {
  opacity: 0;
}

.cards-container {
  overflow: hidden;
  width: 90%;
}

.cards {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.card {
  flex: 0 0 33.333%;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev-btn,
.next-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.prev-btn {
  margin-right: 10px;
}

.next-btn {
  margin-left: 10px;
}

.red-box {
  position: fixed;
  bottom: 10px;
  left: 80px;
  width: 60%;
  height: 100px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
}

.red-box-visible {
  opacity: 1;
}

.red-box-hidden {
  opacity: 0;
}

.selected-card {
  position: absolute;
  top: -70px;
  left: 0px;
  border: 1px solid #ff8787;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.selected-card-visible {
  opacity: 1;
  transform: translateY(0);
}

.selected-card-hidden {
  opacity: 0;
  transform: translateY(-100px);
}

.echarts-for-react {
  height: 100% !important;
}

.table_arrow {
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.5s ease;
}

.table_arrow:hover {
  transform: scale(1.35);
}

.pointer {
  cursor: pointer;
}

.zindex1 {
  z-index: -99999999999;
}

[aria-label="risk_measure_id"] {
  display: none !important;
}

.Sidebar_logo {
  height: 60px;
  object-fit: contain;
  transition: all 0.3s ease;
}
.summary_title {
  color: white;
  font-size: 1.263em;
  font-weight: 500;
  text-align: left !important;
}
.week_box_flex {
  display: flex;
  gap: 10px;
  align-items: center;
}
.gantt_link_control {
  display: none !important;
}
.gantt_task_drag {
  display: none !important;
}
.gantt_task_progress_drag {
  display: none !important;
}
.divider {
  margin: 5px 5px;
  width: 100%;
  opacity: 0.8 !important;
  height: 3px;
}
.gantt_layout_content.gantt_resizer_x.gantt_grid_resize_wrap {
  display: none !important;
}
.gantt-info.gantt-error {
  display: none !important;
}

.url_link {
  color: #2563eb;
}

.url_link:hover {
  color: #1e40af;
  text-decoration: underline;
}
