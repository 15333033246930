.t_table {
  border: 3px solid black;
  border-color: black !important;
}

.t_tbody {
  border-bottom: 1px solid lightgray;
}

.t_td {
  border: 1px solid lightgray;
  text-align: center;
}

.t_th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
  background: #d0ebff !important;
  text-align: center;
}

.t_input {
  border: none;
  background: transparent;
}
.modalEditDialog {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  max-height: 500px;
}
